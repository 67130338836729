import React, { useEffect, useState, useContext, useCallback } from 'react';
import { styled } from '@compiled/react';

import type { MediaClientConfig } from '@atlaskit/media-core';
import { MediaImage } from '@atlaskit/media-image';
import { N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { createRendererMediaProvider } from '@confluence/fabric-media-support';
import { ExternalShareContext } from '@confluence/external-share-context';
import {
	TitleAlignmentType,
	useTitleContentPropertiesForPublishedPage,
	COVERPICTUREWIDTH,
} from '@confluence/custom-sites-extensions';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { type RendererPageCoverPictureComponentProps } from '../pageCoverPictureTypes';
import {
	CALCULATE_SCALED_COVER_IMAGE_HEIGHT,
	COVER_IMAGE_FIXED_WIDTH,
	COVER_IMAGE_FIXED_HEIGHT,
} from '../pageCoverPictureConstants';
import { usePageHeightWidthObserver } from '../usePageHeightWidthObserver';

/* eslint-disable
@atlaskit/ui-styling-standard/no-styled,
@atlaskit/ui-styling-standard/no-exported-styles,
@atlaskit/ui-styling-standard/no-dynamic-styles,
@atlaskit/ui-styling-standard/no-unsafe-values,
@atlaskit/ui-styling-standard/no-imported-style-values,
@atlaskit/design-system/ensure-design-token-usage/preview,
@atlaskit/ui-styling-standard/no-classname-prop,
@atlaskit/ui-styling-standard/enforce-style-prop,
*/

type ImageWrapperProps = {
	isPagePreview: boolean;
	objectPosition: string;
	hasEmoji?: boolean;
	isSpaceOverview?: boolean;
	isEmbeddedConfluence?: boolean;
	dynamicCoverImageHeight?: number;
	isPresenterMode?: boolean;
	isFixedWidthImageOption?: boolean;
};

export const ImageWrapper = styled.img<ImageWrapperProps>({
	height: ({ dynamicCoverImageHeight, isPresenterMode }) =>
		isPresenterMode ? `${dynamicCoverImageHeight}px !important` : `${dynamicCoverImageHeight}px`,
	objectFit: 'cover',
	...({ isFixedWidthImageOption }) =>
		isFixedWidthImageOption
			? {
					width: `${COVER_IMAGE_FIXED_WIDTH}px`,
				}
			: {},
	marginTop: ({ isPagePreview }) => (isPagePreview ? undefined : token('space.200', '16px')),
	marginBottom: (props) =>
		props.isSpaceOverview && props.hasEmoji
			? token('space.200', '16px')
			: props.isPagePreview
				? token('space.0', '0')
				: token('space.400', '32px'),
	width: '100%',
	display: 'flex',
	objectPosition: ({ objectPosition }) => objectPosition,
});

type LoadingDivProps = {
	hasUnsplashImageLoaded?: boolean;
	isPagePreview: boolean;
	isSpaceOverview?: boolean;
	isEmbeddedConfluence?: boolean;
	isFixedWidthImageOption?: boolean;
};

export const LoadingDiv = styled.div<LoadingDivProps>({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	height: ({ isFixedWidthImageOption }) =>
		isFixedWidthImageOption ? `${COVER_IMAGE_FIXED_HEIGHT}px` : CALCULATE_SCALED_COVER_IMAGE_HEIGHT,
	justifyContent: 'center',
	background: token('color.skeleton', N20),
	...({ isFixedWidthImageOption }) =>
		isFixedWidthImageOption
			? {
					marginLeft: 'var(--full-cover-image-margin, 0px)',
					maxWidth: `${COVER_IMAGE_FIXED_WIDTH}px`,
				}
			: {
					marginLeft: 'var(--full-cover-image-margin, 0px)',
				},
	marginBottom: (props) =>
		props.isPagePreview || props.isSpaceOverview
			? token('space.200', '16px')
			: token('space.400', '32px'),
	marginTop: token('space.200', '16px'),
});

export const UnsplashLoadingDiv = styled.div<LoadingDivProps>({
	display: 'flex',
	height: ({ isFixedWidthImageOption }) =>
		isFixedWidthImageOption ? `${COVER_IMAGE_FIXED_HEIGHT}px` : CALCULATE_SCALED_COVER_IMAGE_HEIGHT,
	justifyContent: 'center',
	alignItems: 'center',
	background: token('color.skeleton', N20),
	width: ({ isFixedWidthImageOption, isSpaceOverview, isEmbeddedConfluence }) =>
		isFixedWidthImageOption
			? `${COVER_IMAGE_FIXED_WIDTH}px`
			: isSpaceOverview || isEmbeddedConfluence
				? '100%'
				: 'var(--full-cover-image-width, 100%)',
	visibility: (props) => (props.hasUnsplashImageLoaded ? 'hidden' : 'visible'),
	marginBottom: token('space.200', '16px'),
	position: 'absolute',
	marginTop: token('space.200', '16px'),
});

const UnsplashWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	borderRadius: 'unset',
});

const IdentifierWrapper = ({
	children,
	isPagePreview,
	isEmbeddedConfluence = false,
	isFixedWidthImageOption = false,
}) => (
	<div
		{...useSSRPlaceholderReplaceIdProp()}
		className="renderer-page-cover-picture"
		style={
			isPagePreview && isFixedWidthImageOption
				? {
						display: 'flex',
						justifyContent: 'center',
					}
				: !isPagePreview
					? isFixedWidthImageOption
						? {
								marginLeft: 'var(--full-cover-image-margin, 0px)',
								width: isEmbeddedConfluence ? 'unset' : 'var(--full-cover-image-width, 100%)',
								// need to use non-tokenizable value for marginTop since space.negative.300 (-24px)
								// leaves a small gap between the nav bar and content topper
								marginTop: '-26px',
								display: isFixedWidthImageOption ? 'flex' : 'unset',
								justifyContent: isFixedWidthImageOption ? 'center' : 'unset',
							}
						: {
								marginLeft: 'var(--full-cover-image-margin, 0px)',
								width: isEmbeddedConfluence ? 'unset' : 'var(--full-cover-image-width, 100%)',
								// need to use non-tokenizable value for marginTop since space.negative.300 (-24px)
								// leaves a small gap between the nav bar and content topper
								marginTop: '-26px',
							}
					: {}
		}
	>
		{children}
	</div>
);

const getPageMode = (
	isPagePreview,
	isSpaceOverview,
	isPresenterMode,
	isEmbeddedConfluence,
): string => {
	if (isPagePreview) {
		return 'preview';
	} else if (isSpaceOverview) {
		return 'space-overview';
	} else if (isPresenterMode) {
		return 'presenter';
	} else if (isEmbeddedConfluence) {
		return 'embed';
	} else {
		return 'view';
	}
};

export const RendererPageCoverPictureComponent = ({
	contentId,
	isPagePreview,
	coverPicture,
	contentAppearance,
	isSpaceOverview,
	hasEmoji,
	leftRightPaddingValue,
	isPresenterMode,
	coverPictureWidthOverride,
}: RendererPageCoverPictureComponentProps) => {
	const { isExternalShareRequest: isExternalShare } = useContext(ExternalShareContext);
	const isEmbeddedConfluence = isEmbeddedConfluence_DO_NOT_USE();
	const [mediaConfig, setMediaConfig] = useState<{
		collection: string;
		authProvider: MediaClientConfig['authProvider'];
	} | null>(null);
	const [hasUnsplashImageLoaded, setHasUnsplashImageLoaded] = useState<boolean>(false);

	const { titleContentProperties, coverPictureWidth } = useTitleContentPropertiesForPublishedPage({
		contentId,
	});
	const isFixedWidth =
		coverPictureWidth === COVERPICTUREWIDTH.FIXED ||
		coverPictureWidthOverride === COVERPICTUREWIDTH.FIXED;

	const isFixedWidthImageOption = !isPresenterMode && isFixedWidth;

	const isValidCustomSitesTitleAlignment =
		titleContentProperties?.titleLayoutAlignment == TitleAlignmentType.CENTER ||
		titleContentProperties?.titleLayoutAlignment == TitleAlignmentType.LEFT;

	const titleAlignment =
		isValidCustomSitesTitleAlignment &&
		titleContentProperties &&
		titleContentProperties.titleLayoutAlignment
			? titleContentProperties.titleLayoutAlignment
			: '';

	const leftRightPaddingValueOffset =
		isPagePreview && leftRightPaddingValue ? leftRightPaddingValue : 0;

	const { height } = usePageHeightWidthObserver(
		isPagePreview,
		leftRightPaddingValueOffset,
		isPresenterMode,
	);

	const dynamicHeight = isFixedWidthImageOption ? COVER_IMAGE_FIXED_HEIGHT : height;

	// if we are in page preview, we need to show the draft image/position/appearance since we are in edit mode
	const imageId =
		(isPagePreview ? coverPicture.draft?.value.id : coverPicture.published?.value.id) || '';
	const position =
		(isPagePreview ? coverPicture.draft?.value.position : coverPicture.published?.value.position) ||
		'';
	const objectPosition = `center ${position}%`;
	const appearance = isPagePreview ? contentAppearance.draft : contentAppearance.published;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	// using imageId as key since the query gets outdated information and we need to force a re-render after it gets the
	// correct info
	const isUnsplashCoverImage = !!imageId && imageId.includes('images.unsplash.com');

	const currentPageMode = getPageMode(
		isPagePreview,
		isSpaceOverview,
		isPresenterMode,
		isEmbeddedConfluence,
	);

	const createViewHeaderImageAnalyticsEvent = useCallback(
		(
			isUnsplash: boolean,
			appearance: string,
			pageMode: string,
			pageTitleAlignment: string,
			isFixedWidthCoverImage: boolean,
		) => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'viewed',
					actionSubject: 'HeaderImage',
					actionSubjectId: 'viewPageHeaderImage',
					source: 'viewPageHeaderImage',
					attributes: {
						headerImageType: isUnsplash ? 'unsplash' : 'user-uploaded',
						contentAppearance: appearance,
						pageMode,
						pageTitleAlignment,
						coverImageWidth: isFixedWidthCoverImage ? 'fixed-width' : 'full-width',
					},
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	useEffect(() => {
		createViewHeaderImageAnalyticsEvent(
			isUnsplashCoverImage,
			appearance,
			currentPageMode,
			titleAlignment,
			isFixedWidthImageOption,
		);
	}, [
		isUnsplashCoverImage,
		appearance,
		currentPageMode,
		titleAlignment,
		isFixedWidthImageOption,
		createViewHeaderImageAnalyticsEvent,
	]);

	// Setup media config
	useEffect(() => {
		void createRendererMediaProvider({
			contentId,
			isExternalShare,
		}).then(({ viewMediaClientConfig }) => {
			setMediaConfig({
				collection: `contentId-${contentId}`,
				authProvider: viewMediaClientConfig.authProvider,
			});
		});
	}, [contentId, isExternalShare]);

	// return placeholder if we somehow don't have mediaConfig or imageId
	if (!mediaConfig || !imageId) {
		return (
			<IdentifierWrapper
				isPagePreview={isPagePreview}
				isEmbeddedConfluence={isEmbeddedConfluence}
				isFixedWidthImageOption={isFixedWidthImageOption}
			>
				<LoadingDiv
					isPagePreview={isPagePreview}
					isSpaceOverview={isSpaceOverview}
					isFixedWidthImageOption={isFixedWidthImageOption}
				/>
			</IdentifierWrapper>
		);
	}

	if (isUnsplashCoverImage) {
		return (
			<IdentifierWrapper
				isPagePreview={isPagePreview}
				isEmbeddedConfluence={isEmbeddedConfluence}
				isFixedWidthImageOption={isFixedWidthImageOption}
			>
				<UnsplashWrapper key={imageId}>
					<UnsplashLoadingDiv
						isPagePreview={isPagePreview}
						hasUnsplashImageLoaded={hasUnsplashImageLoaded}
						isSpaceOverview={isSpaceOverview}
						isEmbeddedConfluence={isEmbeddedConfluence}
						isFixedWidthImageOption={isFixedWidthImageOption}
					/>
					<ImageWrapper
						data-testid="unsplash-cover-picture"
						// need this empty alt tag for decorative images
						alt=""
						isPagePreview={isPagePreview}
						src={imageId}
						objectPosition={objectPosition}
						onLoad={() => {
							setHasUnsplashImageLoaded(true);
						}}
						isSpaceOverview={isSpaceOverview}
						hasEmoji={hasEmoji}
						dynamicCoverImageHeight={dynamicHeight}
						isPresenterMode={isPresenterMode}
						isFixedWidthImageOption={isFixedWidthImageOption}
					/>
				</UnsplashWrapper>
			</IdentifierWrapper>
		);
	} else {
		return (
			<MediaImage
				key={imageId}
				identifier={{
					mediaItemType: 'file',
					id: imageId,
					collectionName: mediaConfig?.collection,
				}}
				mediaClientConfig={{
					authProvider: mediaConfig?.authProvider,
				}}
				apiConfig={{
					allowAnimated: true,
					mode: 'full-fit' as const,
					upscale: false,
				}}
			>
				{({ data, loading }) => {
					if (loading) {
						return (
							<IdentifierWrapper
								isPagePreview={isPagePreview}
								isEmbeddedConfluence={isEmbeddedConfluence}
								isFixedWidthImageOption={isFixedWidthImageOption}
							>
								<LoadingDiv
									isPagePreview={isPagePreview}
									isSpaceOverview={isSpaceOverview}
									isFixedWidthImageOption={isFixedWidthImageOption}
								/>
							</IdentifierWrapper>
						);
					}
					if (!data) {
						return null;
					}
					return (
						<IdentifierWrapper
							isPagePreview={isPagePreview}
							isEmbeddedConfluence={isEmbeddedConfluence}
							isFixedWidthImageOption={isFixedWidthImageOption}
						>
							<ImageWrapper
								isPagePreview={isPagePreview}
								src={data.src}
								objectPosition={objectPosition}
								isSpaceOverview={isSpaceOverview}
								hasEmoji={hasEmoji}
								dynamicCoverImageHeight={dynamicHeight}
								isPresenterMode={isPresenterMode}
								isFixedWidthImageOption={isFixedWidthImageOption}
							/>
						</IdentifierWrapper>
					);
				}}
			</MediaImage>
		);
	}
};

export const RendererPageCoverPicture = withTransparentErrorBoundary({
	attribution: Attribution.PAGE_EXTENSIONS,
})(RendererPageCoverPictureComponent);
